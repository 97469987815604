import { WHITE_100 } from '@rategravity/own-up-component-library';
import React from 'react';
import { Ankle } from '../components/ankle';
import { Body, Header } from '../components/header';
import { IconSingle } from '../components/icon-single';
import { ImageMultiple } from '../components/image-multiple';
import { Layout } from '../components/layout';
import { OurStory } from '../components/our-story';
import { founderImages, AboutHeroImage as HeroImage } from '../components/static-images/about';
import { aboutValueProps } from '../data/content/about';

export const About = () => {
  return (
    <Layout>
      <Header image={<HeroImage />} headerText="Welcome to a new approach to mortgage">
        <Body>
          In contrast to the eroded consumer trust in financial services — we are cutting through
          the establishment and connecting with people.
        </Body>
      </Header>
      <OurStory />
      <ImageMultiple
        highlightText="OUR FOUNDERS"
        imageData={founderImages}
        backgroundColor={WHITE_100}
        valueProps={aboutValueProps}
      />
      <IconSingle />
      <Ankle />
    </Layout>
  );
};

export default About;
