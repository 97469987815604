import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpIcon,
  ownUpLightTheme,
  OwnUpSmallSubheadlineMedium,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import { SearchIcon } from '../../images/icons/reskin/search';
import { Body, Button, ButtonWrapper, Headline, Section } from './elements';

const headline = 'Want to work with us?';
const body =
  "We think we have a pretty great team (if we do say so ourselves), and we're always on the lookout for talented folks who want to do things a little differently. Check out our Careers page for current openings.";

export const IconSingle = () => (
  <Section aria-label="Job Openings">
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <OwnUpGridWrapper>
        <OwnUpGridContainer variant="legacy">
          <OwnUpGridOffset xs={0} sm />
          <OwnUpGridItem xs={12} sm={10} lg={8} xl={6}>
            <OwnUpIcon icon={SearchIcon} style={{ justifyContent: 'center' }} height="64px" />
            <Headline>
              <OwnUpSmallSubheadlineMedium variant={'h2'}>{headline}</OwnUpSmallSubheadlineMedium>
            </Headline>
            <Body>{body}</Body>
            <ButtonWrapper>
              <Button />
            </ButtonWrapper>
          </OwnUpGridItem>
          <OwnUpGridOffset xs={0} sm />
        </OwnUpGridContainer>
      </OwnUpGridWrapper>
    </OwnUpThemeProvider>
  </Section>
);
