import { useTrackingInstance } from '@rategravity/1pt-lib';
import { OwnUpLineButton, PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

export const Section = styled.section`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const Headline = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2.5)}px`};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
  }

  text-align: center;
`;

export const Body = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(5)}px`};
  }
`;

export const StyledButton = styled(OwnUpLineButton)<PropsWithTheme<{}>>`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    width: 100%;
    text-align: center;
  }

  margin: auto;
  width: fit-content;
`;

export const Button = () => {
  const trackingInstance = useTrackingInstance();

  return (
    <StyledButton
      href="https://apply.workable.com/own-up/"
      onClick={() => {
        trackingInstance.track('www-about-button-jobs');
      }}
    >
      View postings
    </StyledButton>
  );
};
