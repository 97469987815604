import { ALOE_100, MINT_100, SAGE_100 } from '@rategravity/own-up-component-library';
import React from 'react';

export const SearchIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66699 6.66667C8.66699 5.19391 9.8609 4 11.3337 4H52.667C54.1397 4 55.3337 5.19391 55.3337 6.66667V57.3333C55.3337 58.8061 54.1397 60 52.667 60H11.3337C9.8609 60 8.66699 58.8061 8.66699 57.3333V6.66667Z"
      style={{ fill: SAGE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.667 6.66667H11.3337L11.3337 57.3333H52.667V6.66667ZM11.3337 4C9.8609 4 8.66699 5.19391 8.66699 6.66667V57.3333C8.66699 58.8061 9.8609 60 11.3337 60H52.667C54.1397 60 55.3337 58.8061 55.3337 57.3333V6.66667C55.3337 5.19391 54.1397 4 52.667 4H11.3337Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3337 14.6667C19.3337 13.9303 19.9306 13.3333 20.667 13.3333H43.3337C44.07 13.3333 44.667 13.9303 44.667 14.6667C44.667 15.4031 44.07 16 43.3337 16H20.667C19.9306 16 19.3337 15.4031 19.3337 14.6667Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9912 26.6667C18.9912 25.9303 19.5882 25.3333 20.3245 25.3333L43.6579 25.3333C44.3943 25.3333 44.9912 25.9303 44.9912 26.6667C44.9912 27.4031 44.3943 28 43.6579 28L20.3245 28C19.5882 28 18.9912 27.4031 18.9912 26.6667Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9912 36C18.9912 35.2636 19.5882 34.6667 20.3245 34.6667L43.6579 34.6667C44.3943 34.6667 44.9912 35.2636 44.9912 36C44.9912 36.7364 44.3943 37.3333 43.6579 37.3333L20.3245 37.3333C19.5882 37.3333 18.9912 36.7364 18.9912 36Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3337 49.3333C19.3337 48.597 19.9306 48 20.667 48H36.667C37.4034 48 38.0003 48.597 38.0003 49.3333C38.0003 50.0697 37.4034 50.6667 36.667 50.6667H20.667C19.9306 50.6667 19.3337 50.0697 19.3337 49.3333Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      d="M40.6667 32C40.6667 39.3638 34.6971 45.3333 27.3333 45.3333C19.9695 45.3333 14 39.3638 14 32C14 24.6362 19.9695 18.6667 27.3333 18.6667C34.6971 18.6667 40.6667 24.6362 40.6667 32Z"
      style={{ fill: MINT_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.3333 42.6667C33.2244 42.6667 38 37.891 38 32C38 26.109 33.2244 21.3333 27.3333 21.3333C21.4423 21.3333 16.6667 26.109 16.6667 32C16.6667 37.891 21.4423 42.6667 27.3333 42.6667ZM27.3333 45.3333C34.6971 45.3333 40.6667 39.3638 40.6667 32C40.6667 24.6362 34.6971 18.6667 27.3333 18.6667C19.9695 18.6667 14 24.6362 14 32C14 39.3638 19.9695 45.3333 27.3333 45.3333Z"
      style={{ fill: ALOE_100 }}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.0572 39.7238C35.5779 39.2031 36.4221 39.2031 36.9428 39.7238L47.6095 50.3905C48.1302 50.9112 48.1302 51.7554 47.6095 52.2761C47.0888 52.7968 46.2446 52.7968 45.7239 52.2761L35.0572 41.6095C34.5365 41.0888 34.5365 40.2445 35.0572 39.7238Z"
      style={{ fill: ALOE_100 }}
    />
    <g opacity="0.2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0003 29.3333L16.667 29.3333L16.667 24L38.0003 24V29.3333Z"
        style={{ fill: ALOE_100 }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.0003 38.6667L16.667 38.6667L16.667 33.3333L38.0003 33.3333V38.6667Z"
        style={{ fill: ALOE_100 }}
      />
    </g>
  </svg>
);
