import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  MOSS_100,
  OwnUpBody,
  OwnUpFillButtonSecondary,
  OwnUpSmallHeadlineBook,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  ImageWithText,
  ImageWithTextHeadlineWrapper,
  ImageWithTextProps
} from '../image-with-text';

export const OurStoryBlock = ({ children, ...props }: PropsWithChildren<ImageWithTextProps>) => (
  <ImageWithText {...props}>{children}</ImageWithText>
);

export const OurStoryBlockHeadline = ({ children }: PropsWithChildren<{}>) => (
  <ImageWithTextHeadlineWrapper>
    <OwnUpSmallHeadlineBook variant="h1">{children}</OwnUpSmallHeadlineBook>
  </ImageWithTextHeadlineWrapper>
);

const StyledOurStoryBlockText = styled(OwnUpBody)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;
export const OurStoryBlockText = ({ children }: PropsWithChildren<{}>) => (
  <StyledOurStoryBlockText variant="body2">{children}</StyledOurStoryBlockText>
);

const OurStoryCTAWords = 'See for yourself';
const OurStoryCTAHref = '/onboarding/get-started';

const StyledOurStoryCTADesktop = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('md')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  }
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
`;

export const OurStoryCTADesktop = () => {
  const trackingInstance = useTrackingInstance();

  return (
    <StyledOurStoryCTADesktop>
      <OwnUpFillButtonSecondary
        component={Link}
        to={OurStoryCTAHref}
        onClick={() =>
          trackingInstance.track('Click discover CTA', { 'page-location': 'See for yourself' })
        }
      >
        {OurStoryCTAWords}
      </OwnUpFillButtonSecondary>
    </StyledOurStoryCTADesktop>
  );
};

const StyledOurStoryCTAMobile = styled.div`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.only('xs')} {
    & > a {
      width: 100%;
    }
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    display: flex;
    justify-content: center;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
`;

export const OurStoryCTAMobile = () => {
  const trackingInstance = useTrackingInstance();

  return (
    <StyledOurStoryCTAMobile>
      <OwnUpFillButtonSecondary
        component={Link}
        to={OurStoryCTAHref}
        onClick={() =>
          trackingInstance.track('Click discover CTA', { 'page-location': 'See for yourself' })
        }
      >
        {OurStoryCTAWords}
      </OwnUpFillButtonSecondary>
    </StyledOurStoryCTAMobile>
  );
};

export const OurStoryWrapper = styled.div`
  background-color: ${MOSS_100};
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.backgroundColors?.primaryTheme};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 6)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0, 8)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0, 10)};
  }
`;
