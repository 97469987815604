import { OwnUpGridWrapper, PropsWithTheme } from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { ImageWithText } from '../image-with-text';
import { OurStoryImage, WeBuiltImage, WeRewroteImage } from '../static-images/about';
import {
  OurStoryBlock,
  OurStoryBlockHeadline,
  OurStoryBlockText,
  OurStoryCTADesktop,
  OurStoryCTAMobile,
  OurStoryWrapper
} from './elements';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap should be spacing(6), but GridItems have built-in padding
  // of spacing(1) or spacing(1.5). That may change in the future,
  // in which case these should both be set to 6.
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4.5)}px;
  }
`;

export const OurStory = () => {
  return (
    <OurStoryWrapper>
      <OwnUpGridWrapper>
        <ContentWrapper>
          <ImageWithText title="OUR STORY" image={<OurStoryImage />}>
            <OurStoryBlockHeadline>We are former bankers</OurStoryBlockHeadline>
            <OurStoryBlockText>
              Our founders used to be mortgage bankers, yet they didn’t go to their own bank to get
              a mortgage. Their expertise enabled them to pursue better financial outcomes
              elsewhere. They built Own Up to allow you to get your mortgage the same way they did.
            </OurStoryBlockText>
          </ImageWithText>

          <OurStoryBlock image={<WeRewroteImage />} imageOrientation="left">
            <OurStoryBlockHeadline>We re-wrote the rules</OurStoryBlockHeadline>
            <OurStoryBlockText>
              Unlike mortgage lenders, our interests are aligned with yours, so we can be objective
              about assessing your options. In the end, you save some money, we make some money, and
              the lender makes a loan. Everyone wins.
            </OurStoryBlockText>
          </OurStoryBlock>

          <OurStoryBlock image={<WeBuiltImage />}>
            <OurStoryBlockHeadline>We built a better way to finance a home</OurStoryBlockHeadline>
            <OurStoryBlockText>
              We use technology to simplify the home financing process. Our easy-to-use online tools
              help you evaluate lenders, understand how much home you can afford, compare loan
              offers, and even update pre-approval letters on-demand — a mortgage industry first.
            </OurStoryBlockText>
            <OurStoryCTADesktop />
          </OurStoryBlock>
        </ContentWrapper>
        <OurStoryCTAMobile />
      </OwnUpGridWrapper>
    </OurStoryWrapper>
  );
};
