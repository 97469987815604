import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const AboutHeroImage = () => (
  <StaticImage
    src="../../images/smiling-buyers-blue-shirts.png"
    alt="Two people in a well-lit room with plaid and stripped blue shirts smiling at the camera"
    placeholder="blurred"
    quality={100}
    loading="eager"
  />
);

export const OurStoryImage = () => (
  <StaticImage
    src="../../images/founders.png"
    alt="Own Up Co-founders Patrick and Mike chat at a hightop table in a bright room filled with sunlight"
    placeholder="blurred"
    quality={100}
    style={{ borderRadius: '50%' }}
  />
);

export const WeRewroteImage = () => (
  <StaticImage
    src="../../images/parent-and-baby-at-computer.png"
    alt="A parent with smiling baby on lap browses the internet"
    placeholder="blurred"
    quality={100}
    style={{ borderRadius: '50%' }}
    aspectRatio={1}
  />
);

export const WeBuiltImage = () => (
  <StaticImage
    src="../../images/smiling-new-home-owners.png"
    alt="A laughing couple sits close together on the stoop of their new house. The person on the right holds the key to their house."
    placeholder="blurred"
    quality={100}
    style={{ borderRadius: '50%' }}
    aspectRatio={1}
  />
);

const PatrickImage = () => (
  <StaticImage
    src="../../images/patrick.png"
    alt="Own Up Co-founder Patrick Boyaggi smiles at the camera"
    placeholder="blurred"
    quality={100}
    imgStyle={{ borderRadius: '50%' }}
  />
);

const MikeImage = () => (
  <StaticImage
    src="../../images/mike.png"
    alt="Own Up Co-founder Mike Tassone smiles at the camera"
    placeholder="blurred"
    quality={100}
    imgStyle={{ borderRadius: '50%' }}
  />
);

const BrentImage = () => (
  <StaticImage
    src="../../images/brent.png"
    alt="Own Up Co-founder Brent Shields smiles at the camera"
    placeholder="blurred"
    quality={100}
    imgStyle={{ borderRadius: '50%' }}
  />
);

export const founderImages = {
  'patrick.png': <PatrickImage />,
  'mike.png': <MikeImage />,
  'brent.png': <BrentImage />
};
